import React from 'react';

import type { IDraftJSActivity } from 'api/feed/types';

import { useRicosFormat } from 'common/utils/ricos';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

import { IActivityContentProps } from './types';

export function RichContentActivity(props: IActivityContentProps) {
  const { item } = props;

  const feedItemId = item.feedItemId as string;
  const data = item?.activity?.data as IDraftJSActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRicosFormat(data.content);

  return (
    <RicosViewer
      usage="FeedItem"
      content={content}
      postId={feedItemId}
      containerId={GROUPS_APP_DEFINITION_ID}
      groupId={groupId}
      contentId={item.feedItemId}
    />
  );
}

RichContentActivity.displayName = 'RichContentActivity';
