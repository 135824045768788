import React from 'react';
import { useSelector } from 'react-redux';
import {
  useEnvironment,
  useTranslation,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';
import {
  selectGroup,
  selectAppData,
  selectGroupFetchStatus,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';

import { GroupDetails } from 'Group/Widget/Header/GroupDetails';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

import { Feed } from './Feed';
import { FEED_WIDGET_WRAPPER } from './dataHooks';
import classes from './styles.scss';

export const Widget = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const { group$, feed$ } = useController();

  const limit = settings.get(settingsParams.postsCount);

  const { groupId } = useSelector(selectAppData);
  const group = useSelector(selectGroup(groupId));
  const { loading } = useSelector(selectGroupFetchStatus(groupId));

  const { htmlTag: htmlSubtitleTag } = styles.get(
    stylesParams.feedWidgetTitleFont,
  );

  useDidUpdate(() => {
    group$.fetch(groupId);
  }, [groupId]);

  useDidUpdate(() => {
    if (group.id) {
      feed$.fetch(group.id, { limit }, !isMobile);
    }
  }, [group.id, group.membership, limit]);

  if (loading) {
    return <EmptyState title={<Spinner />} />;
  }

  if (!group.id) {
    return (
      <EmptyState
        title={t('groups-web.feed-widget.empty.title')}
        subtitle={t('groups-web.feed-widget.empty.description')}
      />
    );
  }

  return (
    <Box direction="vertical" gap={isMobile ? '24px' : '48px'}>
      <Show if={experiments.enabled('spec.groups.TitlesAddOns')}>
        <Typography
          as={htmlSubtitleTag as React.ElementType}
          variant="h2-20"
          className={classes.title}
        >
          {settings.get(settingsParams.groupFeedTitle)}
        </Typography>
      </Show>
      <Box gap="SP5" direction="vertical" data-hook={FEED_WIDGET_WRAPPER}>
        <GroupDetails search={false} groupId={group.id} />
        <Feed groupId={group.id} />
      </Box>
    </Box>
  );
};

Widget.displayName = 'Widget';
