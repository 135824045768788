import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Hide } from 'wui/Hide';
import { TextButton } from 'wui/TextButton';
import { Typography } from 'wui/Typography';
import { type TPAComponentProps } from 'wui/types';

import classes from './FeedItemStat.scss';

export interface IFeedItemStatProps extends TPAComponentProps {
  label: string;
  count?: number | null;
  icon: React.ReactElement;

  onClick?(): void;
}

export function FeedItemStat(props: IFeedItemStatProps) {
  const { className, count = 0, label, icon, onClick, ...rest } = props;

  const { isMobile } = useEnvironment();

  if (onClick) {
    return (
      <TextButton
        prefixIcon={icon}
        onClick={onClick}
        className={cls(className, classes.root, classes.clickable)}
        {...rest}
      >
        <span>{isMobile ? count : label}</span>
      </TextButton>
    );
  }

  return (
    <Typography as="p" className={cls(className, classes.root)} {...rest}>
      <Box verticalAlign="middle">
        {icon}

        <span>{isMobile ? count : label}</span>
      </Box>
    </Typography>
  );
}

FeedItemStat.displayName = 'FeedItemStat';
